$main-font: 'Inconsolata';
$code-font: 'Inconsolata';

$google-font: '//fonts.googleapis.com/css?family=';

@import url($google-font + $main-font);
@import url($google-font + $code-font);

$color-yellow: #ffbb00;
$color-green: #7cbb00;
$color-blue: #00a1f1;
$color-red: #f65314;

$color-class: #4EC9B0;
$color-import: #b669a3;
$color-function: #edeb85;
$color-type: #569CD6;
$color-string: #ce9178;
$color-comment: #6A9955;

$color-lib: #B5CEA8;
$color-js: #687687;
$color-invalid: #ff3333;
$color-embed-src: #282828;
$color-markup-xmo: #68685B;
$color-doc: #808080;

$lighter-1: rgba(255,255,255,0.05);
$lighter-2: rgba(255,255,255,0.10);
$lighter-3: rgba(255,255,255,0.15);
$lighter-4: rgba(255,255,255,0.20);
$lighter-5: rgba(255,255,255,0.25);
$lighter-6: rgba(255,255,255,0.30);
$lighter-7: rgba(255,255,255,0.35);
$lighter-8: rgba(255,255,255,0.40);
$lighter-9: rgba(255,255,255,0.45);
$lighter-10: rgba(255,255,255,0.50);
$lighter-11: rgba(255,255,255,0.55);
$lighter-12: rgba(255,255,255,0.60);
$lighter-13: rgba(255,255,255,0.65);
$lighter-14: rgba(255,255,255,0.70);
$lighter-15: rgba(255,255,255,0.75);
$lighter-16: rgba(255,255,255,0.80);
$lighter-17: rgba(255,255,255,0.85);
$lighter-18: rgba(255,255,255,0.90);
$lighter-19: rgba(255,255,255,0.95);
$lighter-20: rgba(255,255,255,1.00);

$darker-1: rgba(0,0,0,0.05);
$darker-2: rgba(0,0,0,0.10);
$darker-3: rgba(0,0,0,0.15);
$darker-4: rgba(0,0,0,0.20);
$darker-5: rgba(0,0,0,0.25);
$darker-6: rgba(0,0,0,0.30);
$darker-7: rgba(0,0,0,0.35);
$darker-8: rgba(0,0,0,0.40);
$darker-9: rgba(0,0,0,0.45);
$darker-10: rgba(0,0,0,0.50);
$darker-11: rgba(0,0,0,0.55);
$darker-12: rgba(0,0,0,0.60);
$darker-13: rgba(0,0,0,0.65);
$darker-14: rgba(0,0,0,0.70);
$darker-15: rgba(0,0,0,0.75);
$darker-16: rgba(0,0,0,0.80);
$darker-16-25: rgba(0,0,0,0.8125);
$darker-16-5: rgba(0,0,0,0.825);
$darker-16-75: rgba(0,0,0,0.8375);
$darker-17: rgba(0,0,0,0.85);
$darker-17-25: rgba(0,0,0,0.8625);
$darker-17-5: rgba(0,0,0,0.875);
$darker-17-75: rgba(0,0,0,0.8875);
$darker-18: rgba(0,0,0,0.90);
$darker-19: rgba(0,0,0,0.95);
$darker-20: rgba(0,0,0,1.00);


.bg-darker-1 {
    background-color: $darker-1;
}
.bg-darker-2 {
    background-color: $darker-2;
}
.bg-darker-3 {
    background-color: $darker-3;
}
.bg-darker-4 {
    background-color: $darker-4;
}
.bg-darker-5 {
    background-color: $darker-5;
}

.bg-lighter-1 {
    background-color: $lighter-1;
}
.bg-lighter-2 {
    background-color: $lighter-2;
}
.bg-lighter-3 {
    background-color: $lighter-3;
}
.bg-lighter-4 {
    background-color: $lighter-4;
}
.bg-lighter-5 {
    background-color: $lighter-5;
}